/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { UserIpDataContext } from "./types";
export const UserIPData = React.createContext<UserIpDataContext>({
  userIpProxy: undefined,
  getUserIpData: () => null,
  setUserIpData: (userData) => undefined,
  ipAddress: "",
  setIpAddress: (userData) => undefined,
});
