import React from "react";
import { isValidURL } from "./utilis";
import { translations } from "../../assets/meemLabels";
import IconInfo from "./components/IconInfo";
interface FooterProps {
  bussinessDetails: any;
  defaultLang: "en" | "ar";
  onClickInfo: () => void;
}
const TermsCondition: React.FC<FooterProps> = (props: FooterProps) => {
  const { bussinessDetails, defaultLang, onClickInfo } = props;
  const logo = require("../../assets/images/main_logo.png");
  const openUrl = () => {
    window.open("https://dukany.io/", "_blank");
  };
  const openTermsAndCondition = (url: string) => {
    window.open(url, "_blank");
  };
  const openEmail = (email: string) => {
    // window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')
    window.open(`mailto:${email}`);
  };
  return (
    <div className="footer">
      <div className="font_s">{translations.powered_by[defaultLang]}</div>
      <div className="footer_logo">
        <a href="" onClick={openUrl}>
          <img src={logo} className="round_cor" alt="" />
        </a>
      </div>
      <div style={{ borderBottom: "1px solid #F3F3F3" }} />
      <div
        className="terms_condition d-flex justify-content-center"
        style={{ marginTop: 10 }}
      >
        <div style={{ textDecoration: "none", marginRight: 5 }}>
          {translations.contact_us[defaultLang]}
          {/* {t("contact_us")} */}
        </div>
        <span onClick={onClickInfo}>
          <IconInfo />
        </span>
        {/* <div
          onClick={() => openEmail(bussinessDetails?.resto_meta?.CONTACT_US)}
          style={{ ...styles.labelStyle, color: "blue", fontSize: 14 }}
        >
          {bussinessDetails?.resto_meta?.CONTACT_US}
        </div> */}
      </div>
      {/* )}  */}
      {bussinessDetails?.resto_meta?.REFUND_POLICY &&
        isValidURL(bussinessDetails?.resto_meta?.REFUND_POLICY) &&
        bussinessDetails?.resto_meta?.REFUND_POLICY !== "" && (
          <>
            <div
              onClick={() =>
                openTermsAndCondition(
                  bussinessDetails?.resto_meta?.REFUND_POLICY
                )
              }
              style={styles.labelStyle}
            >
              {translations.refund_Policy[defaultLang]}
              {/* {t("return_policy")} */}
            </div>
          </>
        )}
      {bussinessDetails?.resto_meta?.PRIVACY_POLICY &&
        isValidURL(bussinessDetails?.resto_meta?.PRIVACY_POLICY) && (
          <div
            onClick={() =>
              openTermsAndCondition(
                bussinessDetails?.resto_meta?.PRIVACY_POLICY
              )
            }
            style={styles.labelStyle}
          >
            {translations.privacy_policy[defaultLang]}
          </div>
        )}
      {bussinessDetails?.resto_meta?.TERM_AND_CONDITIONS &&
        isValidURL(bussinessDetails?.resto_meta?.TERM_AND_CONDITIONS) && (
          <div
            onClick={() =>
              openTermsAndCondition(
                bussinessDetails?.resto_meta?.TERM_AND_CONDITIONS
              )
            }
            style={styles.labelStyle}
          >
            {translations.termsconditon[defaultLang]}
          </div>
        )}
      {new Array(4).fill(4).map(() => (
        <br />
      ))}
    </div>
  );
};

export default TermsCondition;
const styles = {
  labelStyle: {
    marginTop: 10,
    fontSize: 12,
    textDecoration: "underline",
    cursor: "pointer",
    textDecorationSkip: "spaces",
  } as React.CSSProperties,
};
