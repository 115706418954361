import React from "react";
import { translations } from "../../../assets/meemLabels";
import { ObjectKeyLng } from "../HomePage/utils";
interface EmptyCartProps {
  onPress: () => void;
  defaultLanguage: ObjectKeyLng;
}
const EmptyCart: React.FC<EmptyCartProps> = (props: EmptyCartProps) => {
  const { onPress, defaultLanguage } = props;
  return (
    <div
      className="empty-cart-wrapper"
      style={{
        background: "#FFF7E5",
        display: "flex",
        alignItems: "center",
        height: "100vh",
        overflowX: "hidden",
        width: "100%",
        position: "fixed",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: 20, color: "#000000" }}>
        {translations.cart_empty_txt_1[defaultLanguage]}
      </div>
      <div style={{ fontSize: 14, marginBottom: "7vh", marginTop: 3 }}>
        {translations.cart_empty_txt_desc[defaultLanguage]}
      </div>
      <div style={{}}>
        <img
          src={require("../../../assets/images/EmptyCart.png")}
          style={{ width: "100%" }}
        />
      </div>
      <div
        onClick={onPress}
        className="background-color-trash"
        style={{
          cursor: "pointer",
          marginTop: 43,
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          paddingTop: 15,
          paddingBottom: 15,
          justifyContent: "center",
          width: "90%",
        }}
      >
        <span style={{ color: "white", fontWeight: "bold" }} onClick={onPress}>
          {translations.start_shopping[defaultLanguage]}
        </span>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default EmptyCart;
