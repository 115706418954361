import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { translations } from "../../../assets/meemLabels";
import { StoreData } from "../HomePage/utils";
import CrossSvg from "./CrossSvg";
import BlockSelectedItem from "./BlockSelectedItem";
import { numberWithCommas } from "../utilis";
import EmptyCart from "./EmptyCart";
interface Props {
  onPressAddMore: () => void;
  restoData: StoreData | undefined;
  onPressBasket: () => void;
  defaultLang: "en" | "ar";
}
const MiniBasket: React.FC<Props> = (props: Props) => {
  const { onPressAddMore, restoData, onPressBasket, defaultLang } = props;

  const [currency, setCurrency] = useState<string>(
    restoData!.resto_meta?.BUSSINESS_CCY_LANG[defaultLang]
  );
  const [basketQuantity, setBasketQuantity] = useState({
    total: 0,
    price: 0,
  });

  const [parsedShopCart, setParsedShopCart] = useState<any>([]);
  const getItems = () => {
    const getshopCartStrg = localStorage.getItem("shopCart");
    const parsedShopCart =
      getshopCartStrg !== null && JSON.parse(getshopCartStrg);
    setParsedShopCart(parsedShopCart);
    var tempBasket: any = {};
    let old = parsedShopCart;

    var total_basket = 0;
    var total_price = 0;
    old.forEach((element: any, index: number) => {
      if (element.quantity !== 0) {
        total_basket += element.quantity * parseInt(element.actual_price);

        total_price += element.quantity * parseInt(element.actual_price);
      }
    });
    tempBasket = {
      total: total_basket,
      price: total_price,
    };
    setBasketQuantity(tempBasket);
  };
  const updateBasket = (action: string, item: any, index: number) => {
    var new_qunatity = 0;
    var new_price = 0;
    let old = parsedShopCart;
    if (action === "add") {
      new_qunatity = parseInt(item.quantity) + 1;
      new_price = parseInt(old[index].actual_price) * new_qunatity;
      old[index].quantity = new_qunatity;
      old[index].price = new_price;
    } else {
      // if (parseInt(item.quantity)  0) {
      new_qunatity = parseInt(item.quantity) - 1;
      new_price = parseInt(old[index].actual_price) * new_qunatity;
      old[index].quantity = new_qunatity;
      old[index].price = new_price;
      // }
      if (parseInt(item.quantity) === 0) {
        old.splice(index, 1);
      }
    }
    localStorage.setItem("shopCart", JSON.stringify(old));
    getItems();
  };
  useEffect(() => {
    getItems();
  }, []);
  return (
    <div>
      <div id="pop_cart_page1" className="shoppingCart" lang={defaultLang}>
        <div className="popup popup_from_bottom blur-back-ground">
          {parsedShopCart && parsedShopCart.length !== 0 ? (
            <div className="mini-cart-content popup_slide">
              <div>
                {/* <Modal.Header style={{ padding: "20px" }}> */}
                <div
                  onClick={onPressAddMore}
                  className="mini-cart-close-button "
                >
                  <CrossSvg height={30} width={30} wrapperBgColor="white" />
                </div>
                <Modal.Body className="miniBasketBody">
                  <Modal.Title
                    style={{
                      padding: 20,
                      fontSize: "18px",
                    }}
                  >
                    {translations.shoppingBag[defaultLang]}
                  </Modal.Title>
                  {parsedShopCart.map((item: any, index: number) => {
                    return (
                      <div>
                        <BlockSelectedItem
                          showSeperator={true}
                          updateBasket={updateBasket}
                          index={index}
                          item={item}
                          currency={currency}
                          defaultLang={defaultLang}
                          bussinessType={restoData?.resto_meta?.BUSSINESS_TYPE}
                          key={index}
                        />
                        {index === parsedShopCart.length - 1 && (
                          <>
                            <Button
                              onClick={onPressAddMore}
                              className="min-cart-addmore-btn"
                            >
                               {translations.addMore[defaultLang]}
                            </Button>
                            <div className="sub-total-wrapper ">
                              <text>
                                {translations.sub_total[defaultLang]} :{" "}
                              </text>
                              <text style={{ marginLeft: 5 }}>
                                {numberWithCommas(basketQuantity.price)}{" "}
                                {currency}
                              </text>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </Modal.Body>

                <div className="min-cart-footer-wrapper">
                  <Button
                    onClick={onPressBasket}
                    className="min-cart-openbasket-btn"
                  >
                    {translations.openBasket[defaultLang]}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <EmptyCart onPress={onPressAddMore} defaultLanguage={defaultLang} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MiniBasket;
