import React from "react";
// import { ReactComponent as DoneSvg } from "../../../assets/images/done-icon.svg";
interface CrossSvgProps {
  height: number | string;
  width: number | string;
  wrapperBgColor: string;
}
const CrossSvg: React.FC<CrossSvgProps> = (props: CrossSvgProps) => {
  const { height, width, wrapperBgColor } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.407"
      height="32.407"
      viewBox="0 0 32.407 32.407"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_230"
            data-name="Rectangle 230"
            width="13.507"
            height="13.381"
          />
        </clipPath>
      </defs>
      <g
        id="Group_8517"
        data-name="Group 8517"
        transform="translate(-171.296 -92.287)"
      >
        <ellipse
          id="Ellipse_78"
          data-name="Ellipse 78"
          cx="15.181"
          cy="15.181"
          rx="15.181"
          ry="15.181"
          transform="matrix(0.998, 0.07, -0.07, 0.998, 173.414, 92.287)"
          fill="#fff"
        />
        <g
          id="Group_8494"
          data-name="Group 8494"
          transform="translate(180.746 101.8)"
        >
          <g
            id="Group_8260"
            data-name="Group 8260"
            transform="translate(0)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_9079"
              data-name="Path 9079"
              d="M13.506,12.295a2.9,2.9,0,0,1-.2.511,1.243,1.243,0,0,1-1.3.56,1.366,1.366,0,0,1-.838-.429Q9.016,10.775,6.86,8.617a1.585,1.585,0,0,1-.107-.135c-.056.054-.094.088-.13.124L2.248,12.991a1.436,1.436,0,0,1-1.718.191,1.257,1.257,0,0,1-.238-1.836c.045-.054.095-.1.144-.154Q2.628,8.994,4.821,6.8a.931.931,0,0,1,.126-.089c-.057-.061-.088-.1-.121-.129L.442,2.188A1.278,1.278,0,0,1,2.073.233,1.473,1.473,0,0,1,2.262.4Q4.433,2.558,6.6,4.721c.044.043.084.09.137.147.045-.043.083-.076.118-.112Q9.018,2.593,11.178.429a1.273,1.273,0,0,1,2.151.51A1.209,1.209,0,0,1,13.012,2.2Q10.85,4.375,8.684,6.542c-.037.037-.078.07-.129.115.045.048.079.086.115.121q2.152,2.143,4.306,4.284a1.739,1.739,0,0,1,.53.889Z"
              transform="translate(0 -0.001)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CrossSvg;
