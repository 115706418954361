import React, { useState } from "react";
import HomePage from "./pages/HomePage/index";
import ThankyouPage from "./pages/ThankyouPage/Thankyou";
import TrackOrder from "./pages/TrackOrder/TrackOrder";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserIPData } from "../contexts/auth";
import { IProxyIp } from "./pages/HomePage/utils";
import axios from "axios";
const App: React.FC = () => {
  //@ts-ignore
  const userWIfiIp = window.userWifiIp;
  const [userIpProxy, setUserProxyIp] = useState<IProxyIp>({} as IProxyIp);
  const [ipAddress, setIpAddress] = useState<string>("");
  const getDataIpData = async () => {
    console.log("called");
    /*new line we are making requests on server side*/
    const getDataLocal = localStorage.getItem("userIpInfo");
    if (getDataLocal !== null) {
      var parsedIpData = JSON.parse(getDataLocal);
      setUserProxyIp(parsedIpData);
      setIpAddress(parsedIpData.query);
      return;
    }

    const userWifiIpFinal =
      process.env.NODE_ENV === "development" ? "121.91.61.114" : userWIfiIp;
    const resDukanyApi = await axios.get(
      `https://orderapi.dukany.io/api/rjs/proxyIpCall?ip=${userWifiIpFinal}`
    );
    setUserProxyIp(resDukanyApi.data);
    setIpAddress(userWifiIpFinal);
    if (resDukanyApi.data.status !== "fail") {
      localStorage.setItem("userIpInfo", JSON.stringify(resDukanyApi.data));
    }

    /*new line we are making requests on server side*/
  };
  return (
    <UserIPData.Provider
      value={{
        setUserIpData: setUserProxyIp,
        userIpProxy,
        getUserIpData: getDataIpData,
        setIpAddress,
        ipAddress,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/:socailName/:bussinessName" element={<HomePage />} />
          <Route
            path="/:socailName/:bussinessName/track/order"
            element={<TrackOrder />}
          />
          <Route
            path="/:socailName/:bussinessName/thankyou"
            element={<ThankyouPage />}
          />
        </Routes>
      </BrowserRouter>
    </UserIPData.Provider>
    // <HomePage />
  );
};

export default App;
