import React from "react";
import WhatsappContact from "./WhatsappContact";
import CrossSvg from "./CrossSvg";
interface IWhatsappButton {
  onPressClose: () => void;
  whatsappNumber?: string;
}
const WhatsappButton: React.FC<IWhatsappButton> = (props: IWhatsappButton) => {
  const { onPressClose, whatsappNumber } = props;
  const opneWhatsapp = () => {
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };
  // href="https://wa.me/+201203110365?text=welcome"
  return (
    <div className="whats-contact-wrapper">
      <div className="close-button" onClick={onPressClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      </div>
      <button
        // aria-label="Scroll to top"
        type="button"
        onClick={opneWhatsapp}
      >
        <WhatsappContact />
      </button>
    </div>
  );
};

export default WhatsappButton;
